@import url('constants.css');

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  color: var(--white-1);
  text-align: center;
}

.profile {
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: calc(100% - 40px);
}

.profile-img-container {
  width: 50%;
  max-width: 150px;
  max-height: 150px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-name {
  font-size: xx-large;
  margin: 16px 0;
  color: var(--blue-4);
}

.profile-bio {
  font-size: 16px;
  margin-bottom: 8px;
}

.profile-contact {
  font-size: 14px;
  color: #666;
}

.linkedin-link {
  font-size: 14px;
  color: #666;
  text-decoration: underline;
}

@media screen and (min-width: 701px) {
  .profile {
    width: calc(50% - 40px);
  }
}