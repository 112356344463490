.widget {
  background-color: var(--dark-2);
  padding: 20px;
  border-radius: 10px; 
}
  
.widget-grid {
  display: grid;
  /*grid-template-columns: repeat(2, 1fr);*/
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjust for responsive layout */
  gap: 30px;
  margin: 30px;
}

/* Adjust for screens that can fit at least 500px wide elements */
@media (min-width: 500px) {
  .widget-grid {
    grid-template-columns: repeat(2, 1fr); /* Always 2 columns when enough space */
  }
}

/* Target the first widget to span two columns */
.widget-grid > :first-child {
    grid-column: 1 / -1;
}

.widget-content {
  margin-top: 10px;
  color: var(--white-1);
}
  
/* Stop links from looking like links */
.widget a {
  color: var(--white-1);
  background-color: unset;
  border: none;
  text-decoration: none;
}

.widget a:hover {
  color: var(--blue-4);
  background-color: unset;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .widget-grid {
    margin: 15px;
    gap: 20px;
  }
  .widget {
    padding: 15px;
  }
}