@import url('constants.css');

@media (max-width: 700px) {
  /* Applies styles only if the viewport width is less than or equal to 700px */
  .timeline-container {
    padding-left: 8vw;
    padding-right: 8vw;
  }
}

.timeline-container {
  display: flex;
  justify-content: center;
  background-color: var(--dark-1);
  max-width: 600px;
  margin: 0 auto;
}

.timeline {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

/* Line connector */
.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 2px;
  background-color: var(--white-1);
  transform: translateX(-50%);
}

.timeline-item {
  position: relative;
  margin: 50px 0;
}

.timeline-date {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--white-1);
  color: var(--green-1);
  font-size: 14px;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}

.timeline-content {
  background-color: var(--dark-2);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.timeline-content h2 {
  color: var(--white-1);
  margin-top: 0;
  font-size: 24px;
}

.timeline-content p {
  font-size: 16px;
  line-height: 1.5;
  margin-top: 10px;
}

.timeline-item:last-child:before {
  display: none;
}

.timeline-item:after {
  content: '';
  position: absolute;
  top: 50%;
  left: -2px;
  width: 8px;
  height: calc(100% - 50%);
  background-color: var(--white-1);
  transform: translateY(-50%);
  z-index: -1;
}

@media (max-width: 700px) {
  .timeline:before {
    left: 50%;
  }
  .timeline-date {
    left: 50%;
  }
  .timeline-item:before {
    left: -2px;
  }
  .timeline-item:after {
    left: -2px;
    width: 8px;
  }
}
