:root {
  --white-1: rgba(255, 255, 255, 100%);

  --dark-1: rgb(10, 10, 10);
  --dark-1-5: rgb(20, 20, 20);
  --dark-2: rgb(45, 45, 45);
  --dark-3: rgb(62, 62, 62);
  --dark-4: rgb(100, 100, 100);
  --dark-5: rgb(120, 120, 120);

  --green-1: rgb(20, 91, 77);
  --blue-1: rgb(17, 49, 62);
  --blue-4: rgb(111, 211, 250);

  /* General */
  --amplify-colors-background-primary: var(--dark-1-5);

  /* Text field */
  --amplify-components-fieldcontrol-color: var(--white-1);
  --amplify-components-fieldcontrol-border-color: var(--white-1);
  --amplify-components-fieldcontrol-focus-border-color: var(--blue-4);
  --amplify-components-fieldcontrol-outline-color: none;
  --amplify-components-fieldcontrol-outline-width: 0px;
  --amplify-components-fieldcontrol-focus-outline-color: none;
  --amplify-components-field-label-color: var(--white-1);

  /* Sign-In Button */
  --amplify-components-button-primary-background-color: var(--dark-2);
  --amplify-components-button-primary-hover-background-color: var(--dark-3);
  --amplify-components-button-primary-color: var(--white-1);
  --amplify-components-button-primary-hover-color: var(--white-1);

  /* Tabs */
  --amplify-components-tabs-item-active-color: var(--white-1);
  --amplify-components-tabs-item-hover-color: var(--blue-4);
  --amplify-components-tabs-item-color: var(--white-1);
  --amplify-components-authenticator-state-inactive-background-color: var(--dark-2);
  --amplify-components-tabs-item-focus-color: var(--white-1);
  --amplify-components-tabs-item-active-border-color: var(--blue-4);

  /* Links */
  --amplify-components-button-link-color: var(--dark-5);
  --amplify-components-button-link-hover-color: var(--white-1);
  --amplify-components-button-link-hover-background-color: none;

  /* View Password Button */
  --amplify-components-button-border-color: var(--white-1);
  --amplify-components-button-hover-border-color: var(--white-1);
  --amplify-components-button-color: var(--white-1);
  --amplify-components-button-hover-color: var(--blue-4);
  --amplify-components-button-hover-background-color: var(--dark-1-5);
  --amplify-components-button-active-color: var(--blue-4);
  --amplify-components-button-active-background-color: var(--dark-1-5);
  --amplify-components-button-outline-width: 0px;

  /*

  --amplify-components-button-active-border-color: var(--dark-1-5);
  --amplify-components-button-outline-color: var(--dark-1-5);
  --amplify-components-button-focus-outline-color: var(--dark-1-5);
  --amplify-components-button-focus-border-color: var(--dark-1-5);

  --amplify-components-button-loading-background-color: var(--white-1); 
  --amplify-components-button-loading-color: var(--white-1);
  --amplify-components-button-loading-border-color: var(--white-1);
  --amplify-components-button-loading-active-border-color: var(--white-1);

  --amplify-components-button-enabled-background-color: var(--white-1);
  --amplify-components-switchfield-track-checked-background-color: none;
  --amplify-components-checkbox-icon-active-outline-color: none;
  --amplify-components-checkbox-icon-checked-disabled-outline-color: red;
  --amplify-colors-disabled: red;
  --amplify-components-button-disabled-border-color: red;*/
}
