@import url('constants.css');

.hero-image {
  background-image: url('../public/landingPageHeroImage.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--white-1);
  padding-bottom: 5px;
}
.hero-text {
  z-index: 1; /* Make sure the text is displayed above the image */
  color: var(--white-1);
  text-align: center;
}
.hero-image::before {
  content: '';
  background-color: rgba(0, 0, 0, 0.7); /* Adjust the color and opacity as needed */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
@media (max-width: 700px) {
  /* Applies styles only if the viewport width is less than or equal to 700px */
  .content {
    padding-left: 8vw;
    padding-right: 8vw;
  }
}
.content, .hero-text {
  margin: auto;
  color: var(--white-1);
  max-width: 650px;
  /* Adds a little space between each line */
  line-height: 1.4;
  font-size: large;
}
.content h1, .hero-text h1 {
  font-size: xxx-large;
}
.content h1, .content h2, .content h3, .content h4, .hero-text h1, .hero-text h2, .hero-text h3, .hero-text h4 {
  text-align: center;
  font-weight: 400;
}
.content p {
  text-align: left;
}

.demoLink {
  display: flex;
  justify-content: center;
  background-color: var(--dark-2);
  color: var(--white-1);
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: x-large;
  text-align: center;
  text-decoration: none;
  width: fit-content;
  width: -moz-fit-content;
  margin: 0 auto; 
}
.demoLink a:hover, .demoLink Link {
  color: var(--blue-4);
  background-color: unset;
}