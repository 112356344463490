@media screen and (max-width: 700px) {
  .chat_demo {
    padding-left: 8vw;
    padding-right: 8vw;
  }
  .model-selector-button {
    margin-right: 10px;
  }
  .policy-generator {
    padding-left: 0;
    padding-right: 0;
  }
}

h1, h2, h3, h4, p {
  font-weight: 400;
  max-width: 650px;
  margin: auto;
  text-align: center;
}
h1, h2, h3, h4 {
  color: var(--blue-4);
}
p {
  color: var(--white-1);
}
h1 {
  line-height: 100%;
  padding-bottom: 8px;
}

.model-selector {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px auto;
}
.model-selector-button {
  font-size: large;
  background-color: var(--dark-2);
  color: var(--white-1);
  padding: 12px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 5px;
  position: relative;
}

/* The selected button */
.selected {
  background-color: var(--green-1);
}
.lower-opacity {
  opacity: 0.33;
}

.policy-generator {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 650px;
  margin: auto;
  color: var(--white-1);
  text-align: left;
  padding-left: 8vw;
  padding-right: 8vw;
}
.option-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;
}
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  margin-left: 20px;
}
.toggle-switch-container {
  margin-left: 20px;
  margin-right: 20px; /* add margin to create space between each checkbox and the line */
  align-self: center;
  position: relative;
}
.toggle-switch input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #8e8e8e;
  border-radius: 30px;
  transition: all 0.3s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: all 0.3s;
}
input[type="checkbox"]:checked + .slider {
  background-color: var(--blue-4);
}
input[type="checkbox"]:checked + .slider:before {
  transform: translateX(24px);
}
.label-text {
  word-wrap: break-word;
}

.chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  max-width: 650px;
  margin: auto;
  font-size: medium;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  overflow-y: auto; /* add a scrollbar when the chat messages overflow */
  padding: 20px 0px;
  font-size: medium;
}

.chat-bubble {
  max-width: 80%;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.user {
  align-self: flex-end;
  background-color: var(--dark-2);
  color: var(--white-1);
}
.bot {
  align-self: flex-start;
  background-color: var(--green-1);
  color: var(--white-1);
}

.chatbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: auto;
  margin: auto;
  max-width: 650px;
}

.chatbox textarea {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: auto;
  border: none;
  background-color: var(--dark-2);
  color: var(--white-1);
  font-size: medium;
  font-family: inherit;
  resize: none;
  text-align: left;
  padding: 10px; /* Add padding */
  border-radius: 10px; /* Add rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow */
  margin-bottom: 20px; /* Add margin */
}

.typing-indicator {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 22px;
}

.typing-indicator .dot {
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--white-1);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.typing-indicator .dot:nth-child(1) {
  left: 46px;
  animation: typing 0.6s infinite;
  animation-delay: 0.6s;
}
.typing-indicator .dot:nth-child(2) {
  left: 26px;
  animation: typing 0.6s infinite;
  animation-delay: 0.3s;
}
.typing-indicator .dot:nth-child(3) {
  left: 6px;
  animation: typing 0.9s infinite;
}

@keyframes typing {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  50% {
    transform: translate(0, 10px);
    opacity: 0.5;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.imageContainer {
  display: flex;
  justify-content: center;
  max-width: 650px;
  margin: 0 auto;
}
.imageContainer img {
  max-width: 50%;
  object-fit: contain;
  object-position: center;
}
