.postsWidget {
    background-color: unset;
    text-align: left;
    word-wrap: break-word;
    border: none;
}
  
.postsWidget p:hover {
    cursor: pointer;
    color: var(--blue-4);
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.pagination button {
    padding: 5px 10px;
    border: none;
    color: var(--white-1);
    background-color: unset;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.pagination button:hover {
    color: var(--blue-4);
}

.pagination button:disabled {
    display: none;
}

#watchtower {
    width: 100%;
    border-collapse: collapse;
}

#watchtower td {
    padding: 10px; 
    border: none;
}

#watchtower tr:hover {
    background-color: var(--dark-3);
    border-color: var(--dark-3);
}

#watchtower thead tr:hover  {
    background-color: unset;
}

.context-menu {
    position: absolute;
    background-color: var(--dark-1);
    border-radius: 10px; 
    border: 2px solid var(--dark-3);
    padding: 10px;
    z-index: 1000; /* High z-index to ensure it appears above other elements */
}
.context-menu caption{
    text-decoration: underline;
}
.context-menu tr:hover {
    cursor: pointer;
    color: var(--blue-4);
}

/* Show the menu when a user is selected */
.selected .context-menu {
    display: block;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .pagination {
    gap: 5px;
  }
}
