.menuBar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* Add support for Safari */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-left: 10vw;
  padding-right: 10vw;
  font-size: xx-large;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 auto; /* Center the menu bar horizontally */
  flex-wrap: nowrap;
  background-color: var(--dark-2);
}

.menuBar > * {
  white-space: nowrap;
  flex-shrink: 0; /* Prevent items from shrinking */
}

@media (max-width: 700px) {
  /* Applies styles only if the viewport width is less than or equal to 700px */
  .menuBar {
    padding-left: 2vw;
    padding-right: 2vw;
    font-size: calc(4vw + 0.5em);
  }
}

/* Stop links from looking like links */
.menuBar a, .menuBar button {
  color: var(--white-1);
  background-color: unset;
  border: none;
  text-decoration: none;
}
.menuBar a:hover, .menuBar button:hover {
  color: var(--blue-4);
  background-color: unset;
}

.logoLink {
  display: flex;
  align-items: center;
}

.logoImg {
  height: 1em; 
  width: 1em; 
  margin-right: 0.5em; 
}

.authenticator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  max-width: fit-content;
  max-height: fit-content;
  display: flex;
  height: auto;
  z-index: 1000;
    border-radius: 10px; 
}
